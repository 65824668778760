<template>
  <router-view />
</template>

<script>
export default {
  name: 'Simulation',
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
